





















import {Component, Vue} from 'vue-property-decorator';
import {getList} from '@/api/shipCard'
import {makeOrder} from '@/api/shipCardOrder'

import CardContract from '@/components/CardContract.vue'

@Component({
  components: {
    CardContract
  }
})
export default class BuyCard extends Vue {
  private cardId = '';
  private remark = ''
  private price: number | undefined
  private cards: any[] = []
  private cardType = 0
  private second: number | undefined
  private loading = false
  private checked = false

  private htShow = false

  private async activated() {
    await this.getCardList();
  }

  private deactivated() {
    this.cardId = ''
    this.checked = false
  }

  private handleClose() {
    this.htShow = false
  }

  private async submitOrder() {
    if (this.cardId) {
      const orderData = {
        cardId: this.cardId,
        remark: this.remark,
        orderState: 0,
        price: this.price,
        discountPrice: this.price,
        cardType: this.cardType,
        second: this.second,
        cardState: 1
      }

      this.loading = true
      const result = await makeOrder(orderData);
      this.loading = false
      await this.$router.push({
        path: '/user/buy-card-order',
        query: {
          orderId: result.id
        }
      });
    }
  }

  private async getCardList() {
    const result = await getList();
    this.cards = result.items;
  }

  private getCardType(cardType: number, second?: number) {
    switch (cardType) {
      case 1:
        return `${second}次`;
      case 2:
        return '月卡';
      case 3:
        return '季卡';
      case 4:
        return '半年卡';
      case 5:
        return '年卡';
      case 6:
        return '两年卡';
      case 7:
        return '家庭卡';
      default:
        return '';
    }
  }

  private handleCardType(item: any) {
    this.cardId = item.id;
    this.remark = this.getCardType(item.cardType, item.second)
    this.price = item.price
    this.cardType = item.cardType
    this.second = item.second
  }
};
