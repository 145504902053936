import request from '@/utils/request'

const url = '/keep-fit/ship-card/'

export function getList(): any {
    return new Promise((resolve, reject) => {
        request({
            url: `${url}list`,
            method: 'get'
        }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
}
