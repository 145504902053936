







































import {Component, Vue, Prop} from 'vue-property-decorator'

@Component({})
export default class CardContract extends Vue {
  @Prop({default: () => false}) private show!: boolean

  private tempShow = this.show

  private handleClose() {
    this.$emit('handleClose')
  }
}
